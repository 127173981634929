.app-container{
    padding: 20px;
}
.ant-tabs > .ant-tabs-nav{
    padding-top: 120px;
    margin: 0 auto;
    width: 100%;
}
.ant-tag{
    margin-top: 2px;
}
.search-input{
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 16px;
    outline: none;
    position: relative;
    font-family: inherit;
    font-size: 14px;
    width: 100%;
}
.search-btn {
    flex-shrink: 0;
    font-family: inherit;
    font-size: 14px;
    padding: 16px;
    margin-left: 10px;
    text-shadow: rgb(0 0 0 / 5%) 0 1px 2px;
    color: #fff;
    border: none;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 0 rgb(59 69 79 / 5%);
    background: linear-gradient(#2da0fa,#3158ee) #2f7cf4;
    cursor: pointer;
    border-radius: 4px;
}
.search-btn:hover {
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 0px 1px inset, rgb(59 69 79 / 30%) 0px 2px 4px;
    background: linear-gradient(#3cabff, #4063f0) #3d84f7;
}
.search-group {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: stretch;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
}

.paper-analytics-container .sui-layout-sidebar{
    display: none;
}
.paper-analytics-container .sui-layout-main {
    width: 100%;
}

.hiddenDom{
    display: none;
}

.echart-box{
    width: 100%;
    height: 400px;
    margin-bottom: 100px;
}
.echart-box:last-child{
    margin-bottom: 0;
}

.echart-bodyContent{
    display: flex;
    flex-direction: column;
}
.echart-switch{
    align-self: flex-end;
}

.imagehidden{
    display: block;
    padding: 0 20px;
}
#main{
    margin: 0 auto;
    padding-top: 100px;
}
.sui-result__ingredients,
.sui-result__expectBrands {
    display: flex;
    flex-wrap: wrap;
}
.sui-layout-header{
    z-index: 999;
}
.sui-expectBrand {
    box-sizing: border-box;
    margin: 4px 8px 0 0;
    color: #096dd9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    height: auto;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 2px;
    opacity: 1;
    transition: all .3s;
}
.sui-ingredient {
    box-sizing: border-box;
    margin: 4px 8px 0 0;
    color: #389e0d;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    height: auto;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 2px;
    opacity: 1;
    transition: all .3s;
}
.sui-gene {
    box-sizing: border-box;
    margin: 4px 8px 0 0;
    color: #389e0d;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    height: auto;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 2px;
    opacity: 1;
    transition: all .3s;
}
@media (max-width: 480px) {
    .app-container{
        padding: 0;
    }
    .echart-box{
        width: 360px;
    }
    .ant-tabs > .ant-tabs-nav{
        padding: 100px 15px 0 15px;
    }
}
@media (min-width: 1300px) {
    .app-container{
        padding: 0;
    }
    .ant-tabs > .ant-tabs-nav{
        width: 1300px;
    }
    .echart-box{
        width: 1300px;
    }
}